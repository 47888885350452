import React from "react";
import { Fade, Box, Typography } from "@mui/material";
import Head from "next/head";
import { oswald } from "~/theme";

export default function Index(): React.ReactElement {
  return (
    <>
      <Head>
        <title>
          Converge – Unify community knowledge, communication, and learning
        </title>
      </Head>
      <Box
        sx={{
          backgroundColor: "#38B4FA",
          minHeight: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={true} timeout={800}>
          <Box>
            <img
              style={{
                maxWidth: "40%",
                paddingTop: "30%",
                paddingBottom: "10px",
                flexDirection: "row",
              }}
              src="https://firebasestorage.googleapis.com/v0/b/converge-mt-prod.appspot.com/o/converge%20marketing%20page%2Fconverge_logo_white.png?alt=media&token=419046bd-fec1-4c4a-9a36-d8e790c48cac"
              alt="Converge logo"
            />
            <Typography
              variant="h2"
              sx={[
                oswald.style,
                {
                  color: "white",
                  fontWeight: "400",
                  paddingBottom: "30%",
                },
              ]}
            >
              CONVERGE
            </Typography>
          </Box>
        </Fade>
      </Box>
    </>
  );
}
